import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ChatMessage from './chat-message';
import styled, { css } from 'styled-components';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Rating from './rating';
import SideBar from './side-bar';

import LocationIcon from './assets/location-icon.svg';
import ClockIcon from './assets/clock-icon.svg';
import WebIcon from './assets/web-icon.svg';
import EmailIcon from './assets/email-icon.svg';
import PhoneIcon from './assets/phone-icon.svg';
import ImavenIcon from './assets/imaven-icon.svg'
import LoadingIcon from './assets/load-line.svg'
import PageIcon from './assets/page-icon.svg'
import NightModeIcon from './assets/night-mode-icon.svg'
import LightModeIcon from './assets/light-mode-icon.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  background-color: ${(props) => (props.darkMode ? '#333' : 'white')};
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
  height: 100%;
`;

export const ChatWindow = styled.div`
  background-color: ${(props) => (props.darkMode ? '#333' : 'white')};
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  height: 100%;
`;

export const IFrameChatWindow = styled.div`
  background-color: ${(props) => (props.darkMode ? '#222' : 'white')};
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
  border-radius: 8px;
  min-width: 93%;
  max-width: 400px;
  height: 76vh;
  padding: 5px 10px 5px 10px;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: ${(props) => (props.darkMode ? '#444' : '#007BFF')};
  color: ${(props) => (props.darkMode ? '#ddd' : 'white')};
  border: none;
  border-radius: 5px;
  font-size: 16px;
`;

const SendMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => (props.darkMode ? '#555' : 'lightgray')};
  background-color: ${(props) => (props.darkMode ? '#333' : 'white')};
  border-radius: 12px;
  padding: 0.1rem;
  margin-left: 15px;
  margin-right: 15px;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const Input = styled.input`
  border: none;
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: ${(props) => (props.darkMode ? '#ccc' : 'gray')};
  margin-left: 0.1rem;
  flex-grow: 1;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:focus {
    outline: none;
    border-color: ${(props) => (props.darkMode ? '#555' : '#007BFF')};
  }
`;

const MessagesContiner = styled.div`
  height: 90%;
  margin-bottom: 10px;
  overflow-y: auto;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: ${(props) => (props.darkMode ? '#333' : 'transparent')};
`;

const Header = styled.div`
  height: 5vh;
  width: 100%;
  background-color: ${(props) => (props.darkMode ? '#444' : 'white')};
  border-right: 1px solid ${(props) => (props.darkMode ? '#555' : '#B9CDD8')};
  border-bottom: 1px solid ${(props) => (props.darkMode ? '#555' : '#B9CDD8')};
`;

const SideHeader = styled.div`
  height: 5vh;
  width: 100%;
  border-bottom: 1px solid ${(props) => (props.darkMode ? '#555' : '#B9CDD8')};
`;

const IframeHeader = styled.div`
  height: 16vh;
  width: 100vw;
  background: ${(props) =>
        props.darkMode ? 'linear-gradient(90deg, #1a1a1a, #2b2b2b)' : 'linear-gradient(90deg, #004c99, #009BF3)'};
  color: white;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
`;

const LogoWrapper = styled.div`
  height: 100%;
  position: relative;
  top: var(--logo-vertical-position);
  display: flex;
  align-items: center;
  font-family: 'Inria Sans', sans-serif;
  padding-left: 1px;
`;

const Logo = styled.div`
  background-image: url(${(props) => props.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 75%;
  padding-right: 5px;
`;

const Footer = styled.div`
  height: 10vh;
  width: 100vw;
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: ${(props) => (props.darkMode ? '#333' : 'red')};
`;

const SideFooter = styled.div`
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: ${(props) => (props.darkMode ? '#2a2a2a' : '#F8FBFF')};
`;

const PoweredByWrapper = styled.div`
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
`;

const PoweredBy = styled.a`
  display: flex;
  align-items: center;
`;

const PowerByText = styled.span`
  margin: 0 5px;
  color: ${(props) => (props.darkMode ? '#ddd' : 'inherit')};
`;

const ExitButtonWrapper = styled.div`
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 20px;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.darkMode ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.9)')};
  z-index: 9999;
`;

const FadeInWrapper = styled.div`
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity 4s ease-in-out;
`;

const ExpertImageWrapper = styled.div`
  width: 100%;
  height: 247px;
`;

const ExpertTitle = styled.div`
  border-bottom: 1px solid ${(props) => (props.darkMode ? '#555' : '#B9CDD8')};
  width: 100%;
  font-family: Helvetica Neue LT;
  font-size: 24px;
  font-weight: 300;
  line-height: 30.5px;
  text-align: left;
  color: ${(props) => (props.darkMode ? '#ddd' : 'inherit')};
`;

const ExpertName = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 30.5px;
  text-align: left;
  margin-bottom: 4px; // Reduced from default
  color: ${(props) => (props.darkMode ? '#ddd' : 'inherit')};
`;

const ExpertRole = styled.div`
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 8px; // Reduced spacing before rating
  color: ${(props) => (props.darkMode ? '#aaa' : 'inherit')};
`;

const InfoContainer = styled.div`
  padding: 20px;
  color: ${(props) => (props.darkMode ? '#ccc' : '#333')};
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px; // Increased from 20px to 35px
  padding: 5px 0; // Added padding for better spacing
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.4; // Added for better text spacing
  flex: 1;
  padding-left: 5px; // Added small padding from icon
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
`;

const HighlightedText = styled.span`
  color: ${(props) => (props.darkMode ? '#7CFC00' : 'green')};
`;

const GrayText = styled.span`
  color: ${(props) => (props.darkMode ? '#aaa' : '#777')};
`;

const InfoIconWrapper = styled.div`
  margin-right: 15px; // Increased from 10px to 15px
  font-size: 20px;
  color: ${(props) => (props.darkMode ? '#bbb' : '#555')};
  align-self: flex-start;
  display: flex;
  min-width: 30px; // Added to ensure consistent icon spacing
  justify-content: center;
`;


const createMessagesHash = (messages) => messages.map(msg => msg.message).join('|');

const ChatPage = () => {

    const { apikey } = useParams();
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [error, setError] = useState("");
    const [isIframe, setIsIframe] = useState(true);
    const listRef = useRef(null);
    const inputRef = useRef(null);
    const uploadRef = useRef(null);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [emojiPickerOn, setEmojiPickerOn] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const [expertData, setExpertData] = useState(
        {
            expertName: '',
            imgUrl: '',
            expertTilte: '',
            stars: 0,
            reviewAmount: 0,
            expertInfo: {
                location: "",
                hourClose: "",
                site: "",
                email: "",
                phone: ""
            },
        })

    const base_url = "https://urchin-app-t9sq7.ondigitalocean.app"
    // const base_url = "http://localhost:3030"

    useEffect(() => {
        setIsIframe(window.self !== window.top);
    }, []);


    const toggleMode = () => {
        setDarkMode(!darkMode)
    }

    const firstMessage =
        `<p>
            <strong>Hello there!</strong>
            <br/>
            <br/>
            <span>
                To give you the best advice, we need to understand your situation. 
                Our intelligent AI assistant will help gather information about your issue. 
                Just tell us what you need help with - whether it's Financial Planning, 
                Health and Wellness, Personal Development and Life/Career Coaching, or Shopping .
            </span>
            <br/>
            <br/>
            <span>
                Actually, the more details you provide, the better advice we can offer!
                The AI assistant will ask some questions, 
                then a human advisor will review and join to offer personalized guidance.
            </span>
            <br/>
            <br/>
            <span>
                Ready? Let's begin! What can we assist you with today?
            </span>
        </p>`;

    useEffect(() => {
        if (apikey) {

            window.addEventListener('message', (event) => {
                if (event.data.action === 'insertEmoji') {
                    const emoji = event.data.emoji;
                    setCurrentMessage((prevMessage) => prevMessage + emoji);
                }
            });

            const fetchData = async () => {
                try {
                    const response = await fetch(base_url + '/chat/start', {
                        method: 'GET', headers: {
                            'x-apikey': apikey
                        }
                    });
                    if (!response.ok) {
                        // const data = await response.json();
                        throw "Network response was not ok";
                    }

                    setMessages([
                        { message: firstMessage, type: "system", timestamp: new Date().toLocaleString() }
                    ])
                    const result = await response.json();
                    localStorage.setItem('imavenconvoid', result.response)
                    localStorage.setItem('threadid', 'none');
                    localStorage.setItem('asstId', 'asst_5WqDk3WVgQCoOlJO18quwhlh');
                    setExpertData(result.expertData)
                } catch (err) {
                    setError(err);
                } finally {
                    setLoading(false)
                }
            };

            fetchData();
        }

    }, []);

    const messagesHash = createMessagesHash(messages);

    // useEffect(() => {
    //     if (listRef && listRef.current && !isIframe) {
    //         setTimeout(() => {
    //             listRef.current.scrollTop = listRef.current.scrollHeight;
    //         }, 10);
    //     }
    // }, [messagesHash, isIframe]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const sendMessage = () => {

        if (currentMessage.trim().length < 2)
            return

        setLoadingMessage(true)
        const newMessage = { message: currentMessage, type: 'user', timestamp: new Date().toLocaleString() };
        let temp = [...messages, newMessage]
        setMessages(temp)
        setCurrentMessage("")

        const gptMessage = { message: "", type: 'gpt', timestamp: new Date().toLocaleString() };
        temp.push(gptMessage)
        setMessages(temp)

        setTimeout(() => {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }, 0);

        fetch(base_url + '/chat/sendMessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Conversation-ID': localStorage.getItem('imavenconvoid'),
                'X-Thread-ID': localStorage.getItem('threadid'),
                'X-Asst-ID': localStorage.getItem('asstId'),
                'x-apikey': apikey
            },
            body: JSON.stringify({ message: currentMessage })
        })
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message || 'Error sending message. Please try again.');
                }
                return data;
            })
            .then(res => {
                localStorage.setItem('threadid', res.thread_id);
                localStorage.setItem('asstId', res.asstId);
                updateLastMessage(res.response)

                setTimeout(() => {
                    listRef.current.scrollTop = listRef.current.scrollHeight;
                    inputRef.current.focus();
                }, 10);

                if (res.finishFlag) {
                    const lastUrl = `
                            <p>
                                <strong>Thank you for sharing your information!</strong>
                                <br/>
                                <span>
                                    We are now processing your details and will forward them to a human adviser for further review. 
                                    To continue your consultation and connect with an adviser, please 
                                    <a href="https://platform.imavenai.com/setMetting/${res.id}/${res.thread_id}" target="_blank" rel="noopener noreferrer">click here</a>. 
                                    Your conversation will proceed on the Imaven platform.
                                </span>                                   
                            </p>`;

                    const lastMessage = { message: lastUrl, type: 'system', timestamp: new Date().toLocaleString() };

                    setMessages((prevMessages) => [...prevMessages, lastMessage]);
                }

            })
            .catch(error => {
                console.log(error);
                updateLastMessage(error)

                const systemMessage = `
                    <p>
                        Oops! It looks like we're having some technical difficulties right now.
                        Please give it a moment and try continuing with the conversation shortly.
                        Thank you for your patience!
                    </p>
                `
                const newSystemMessage = {
                    message: systemMessage, type: "system",
                    timestamp: new Date().toLocaleString()
                };

                let temp = [...messages, newSystemMessage]
                setMessages(temp)
            })
            .finally(() => {
                setLoadingMessage(false)
            })
    }

    const updateLastMessage = (message) => {
        setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages[newMessages.length - 1] = {
                ...newMessages[newMessages.length - 1],
                message: message,

            };
            return newMessages;
        });
    }

    const handleInputChange = (event) => {
        setCurrentMessage(event.target.value);
    }

    const uploadDoc = () => {
        uploadRef.current.click()
    }

    const handleFileChange = async (event) => {
        setLoadingMessage(true);
        try {
            const files = event.target.files;
            if (files.length === 0)
                return;

            const formData = new FormData();
            let fileNames = [];

            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
                fileNames.push(files[i].name);  // Collect file names
            }

            const userMessage = `File uploaded: ${fileNames.join(", ")}`;
            const newMessage = { message: userMessage, type: 'user', timestamp: new Date().toLocaleString() };
            let temp = [...messages, newMessage];
            setMessages(temp);

            const response = await fetch(base_url + '/upload', {
                method: 'POST',
                body: formData,
                credentials: 'include',
                headers: {
                    'X-Conversation-ID': localStorage.getItem('imavenconvoid'),
                    'X-Thread-ID': localStorage.getItem('threadid'),
                }
            });

            if (response.ok) {
                const gptMessage = { message: "", type: 'gpt', timestamp: new Date().toLocaleString() };
                temp.push(gptMessage);
                setMessages(temp);
                setLoadingMessage(true);

                const aiResponse = await fetch(base_url + '/upload/sendFileData', {
                    body: JSON.stringify({ flag: true }),
                    method: 'POST',
                    headers: {
                        'X-Conversation-ID': localStorage.getItem('imavenconvoid'),
                        'X-Thread-ID': localStorage.getItem('threadid'),
                        'Content-Type': 'application/json',
                    }
                });

                const data = await aiResponse.json();
                updateLastMessage(data.response);
            }
        } catch (error) {
            console.log("File upload error: ", error);
            updateLastMessage(error);
        } finally {
            setLoadingMessage(false);
        }
    };

    const closeChat = () => {
        window.parent.postMessage({ action: 'closeChat' }, '*');
    };

    const openEmojiPickerOutside = () => {
        if (emojiPickerOn) {
            setEmojiPickerOn(false)
        }
    }

    const openEmojiPicker = () => {
        window.parent.postMessage({ action: 'openEmojiPicker' }, '*')
        setEmojiPickerOn(!emojiPickerOn)
    }

    const emojiSelected = (event) => {
        console.log(event);
        setCurrentMessage(currentMessage + event.native)
    }

    return (
        <>
            {
                loading ? (
                    <LoadingOverlay darkMode={darkMode}>
                        <img src={LoadingIcon} alt="Loading..." width={'20px'} height={'20px'} />
                    </LoadingOverlay>
                ) : (
                    <FadeInWrapper loaded={!loading}>
                        {
                            error.length > 0 ? (
                                <div>
                                    {error}
                                </div>
                            ) : (isIframe ? (
                                <div style={{ width: "320px", borderRadius: "12px", height: '500px', bottom: '1',  zIndex: '10002' }}>
                                    <IframeHeader darkMode={darkMode}>
                                        <div style={{
                                            width: '100%', display: 'flex', height: '70%',
                                            justifyContent: 'space-between', alignItems: 'center'
                                        }}>
                                            <LogoWrapper>
                                                <Logo url={ImavenIcon}></Logo>
                                                <strong>We're Here to Help!</strong>
                                            </LogoWrapper>
                                            <ExitButtonWrapper>
                                                <img onClick={closeChat} src={`https://urchin-app-t9sq7.ondigitalocean.app/icons/exist-icon?api_key=${apikey}`} />
                                            </ExitButtonWrapper>
                                        </div>
                                        <div style={{
                                            width: '100%', height: '25%',
                                            borderTop: "1px solid #cccccc",
                                            display: 'flex', alignItems: 'center', lineHeight: '1'
                                        }}>
                                            <span>&nbsp;&nbsp;Instant AI assistance.</span>
                                        </div>
                                    </IframeHeader>
                                    <IFrameChatWindow darkMode={darkMode}>

                                        <MessagesContiner ref={listRef} darkMode={darkMode}>
                                            {messages.map((item, index) => (
                                                <ChatMessage message={item.message} type={item.type} apikey={apikey} darkMode={darkMode}
                                                    key={index} loading={loadingMessage && index === messages.length - 1} timestamp={item.timestamp} />
                                            ))}
                                        </MessagesContiner>

                                        <SendMessageWrapper darkMode={darkMode}>
                                            <input name="files" type="file" id="file-input" style={{ display: 'none' }}
                                                ref={uploadRef}
                                                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt,.txt,.html,.md,.csv,.json,.epub"
                                                multiple onChange={handleFileChange} darkMode={darkMode} />

                                            <IconWrapper disabled={loadingMessage} onClick={uploadDoc}>
                                                <img src={`https://urchin-app-t9sq7.ondigitalocean.app/icons/upload-icon?api_key=${apikey}`}
                                                    alt="upload icon" />
                                            </IconWrapper>

                                            <IconWrapper onClick={openEmojiPicker}>
                                                <img src={`https://urchin-app-t9sq7.ondigitalocean.app/icons/emoji-icon?api_key=${apikey}`}
                                                    alt="emoji icon" />
                                            </IconWrapper>

                                            <Input ref={inputRef} placeholder='Type your message...' type='text'
                                                value={currentMessage} onChange={handleInputChange} disabled={loadingMessage}
                                                onKeyDown={(e) => e.key === 'Enter' && !loadingMessage && sendMessage()} darkMode={darkMode} />

                                            <IconWrapper style={{ marginRight: '5px' }} onClick={sendMessage} disabled={loadingMessage}>
                                                <img src={`https://urchin-app-t9sq7.ondigitalocean.app/icons/send-btn?api_key=${apikey}`}
                                                    alt="send icon" />
                                            </IconWrapper>
                                        </SendMessageWrapper>

                                        <PoweredByWrapper darkMode={darkMode}>
                                            <PoweredBy href="https://imavenai.com/" target="_blank" rel="noopener noreferrer">
                                                <PowerByText darkMode={darkMode}>POWERED BY</PowerByText>
                                                <img src={base_url + `/icons/small-logo?api_key=${apikey}`} alt="IMAVEN Logo"
                                                    width={'25px'} height={'20px'} />
                                                <PowerByText darkMode={darkMode}>IMAVEN</PowerByText>
                                            </PoweredBy>
                                        </PoweredByWrapper>

                                    </IFrameChatWindow>
                                </div>

                            ) : (
                                <div style={{
                                    display: 'flex', flexDirection: 'row', backgroundColor: darkMode ? 'rgb(30, 30, 30)' : 'rgb(246, 248, 252)', height: '100vh'
                                }}>
                                    <div style={{ width: '15%', padding: '20px 10px 20px 20px' }}>
                                        <div style={{
                                            height: '100%', backgroundColor: darkMode ? '#333' : 'white',
                                            borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                            overflow: 'hidden'
                                        }}>
                                            <SideBar darkMode={darkMode}></SideBar>
                                        </div>

                                    </div>

                                    <div style={{ width: '65%', padding: '20px 10px 20px 10px' }}>

                                        <Container>
                                            <ChatWindow darkMode={darkMode}>

                                                <MessagesContiner ref={listRef} darkMode={darkMode}>
                                                    {messages.map((item, index) => (
                                                        <ChatMessage message={item.message} type={item.type} apikey={apikey} darkMode={darkMode}
                                                            key={index} loading={loadingMessage && index === messages.length - 1} />
                                                    ))}
                                                </MessagesContiner>

                                                <div style={{ position: 'relative' }}>
                                                    {emojiPickerOn &&
                                                        <div style={{ bottom: '138%', position: 'absolute', left: "1%", zIndex: '100' }}>
                                                            <Picker data={data} onEmojiSelect={emojiSelected} theme="light" />
                                                        </div>
                                                    }

                                                    <SendMessageWrapper darkMode={darkMode}>
                                                        <input name="files" type="file" id="file-input" style={{ display: 'none' }}
                                                            ref={uploadRef}
                                                            accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt,.txt,.html,.md,.csv,.json,.epub"
                                                            multiple onChange={handleFileChange} darkMode={darkMode} />

                                                        <IconWrapper disabled={loadingMessage} onClick={uploadDoc}>
                                                            <img src={`https://urchin-app-t9sq7.ondigitalocean.app/icons/upload-icon?api_key=${apikey}`}
                                                                alt="upload icon" />
                                                        </IconWrapper>

                                                        <IconWrapper disabled={loadingMessage} onClick={openEmojiPicker}>
                                                            <img src={`https://urchin-app-t9sq7.ondigitalocean.app/icons/emoji-icon?api_key=${apikey}`}
                                                                alt="emoji icon" />
                                                        </IconWrapper>

                                                        <Input ref={inputRef} placeholder='Type your message...' type='text'
                                                            value={currentMessage} onChange={handleInputChange} disabled={loadingMessage}
                                                            onKeyDown={(e) => e.key === 'Enter' && !loadingMessage && sendMessage()} darkMode={darkMode} />

                                                        <IconWrapper style={{ marginRight: '5px' }} onClick={sendMessage} disabled={loadingMessage}>
                                                            <img src={`https://urchin-app-t9sq7.ondigitalocean.app/icons/send-btn?api_key=${apikey}`}
                                                                alt="send icon" />
                                                        </IconWrapper>
                                                    </SendMessageWrapper>
                                                </div>


                                            </ChatWindow>
                                        </Container>

                                    </div>

                                    <div style={{ width: '20%', padding: '20px 20px 20px 10px' }}>

                                        <SideContainer darkMode={darkMode}>

                                            <div style={{
                                                padding: '10px', height: '100%', overflowY: 'auto',
                                                position: 'relative'
                                            }}>
                                                <div style={{
                                                    width: '20px', height: '20px',
                                                    position: 'absolute', right: '10px',
                                                    cursor: 'pointer'
                                                }} onClick={toggleMode}>
                                                    <img src={darkMode ? LightModeIcon : NightModeIcon} />
                                                </div>

                                                <h2>Adviser Info</h2>

                                                {/* image */}
                                                <ExpertImageWrapper>
                                                    <img 
                                                        src={expertData.imgUrl} 
                                                        width={'100%'} 
                                                        height={'100%'} 
                                                        alt='expert image' 
                                                        style={{ borderRadius: '10px' }} // Add corner radius here
                                                    />
                                                </ExpertImageWrapper>

                                                <ExpertTitle darkMode={darkMode}>
                                                    <div style={{ height: '100%', padding: '20px' }}>
                                                        <ExpertName darkMode={darkMode}>{expertData.expertName}</ExpertName>
                                                        <ExpertRole>{expertData.expertTilte}</ExpertRole>
                                                        <Rating rating={expertData.stars} reviews={expertData.reviewAmount} />
                                                    </div>
                                                </ExpertTitle>

                                                <InfoContainer>
                                                    <ContactItem>
                                                        <InfoIconWrapper darkMode={darkMode}>
                                                            <img src={LocationIcon} width={'25px'} alt='location icon' />
                                                        </InfoIconWrapper>
                                                        <Text darkMode={darkMode}>{expertData.expertInfo.location}</Text>
                                                    </ContactItem>

                                                    <ContactItem>
                                                        <InfoIconWrapper darkMode={darkMode}>
                                                            <img src={ClockIcon} alt='clock icon' />
                                                        </InfoIconWrapper>
                                                        <Text darkMode={darkMode}>
                                                            <HighlightedText darkMode={darkMode}>Open</HighlightedText> · Closes {expertData.expertInfo.hourClose}
                                                        </Text>
                                                    </ContactItem>

                                                    <ContactItem>
                                                        <InfoIconWrapper darkMode={darkMode}>
                                                            <img src={WebIcon} alt='web icon' />
                                                        </InfoIconWrapper>
                                                        <Text darkMode={darkMode}>{expertData.expertInfo.site}</Text>
                                                    </ContactItem>

                                                    <ContactItem>
                                                        <InfoIconWrapper darkMode={darkMode}>
                                                            <img src={EmailIcon} alt='email icon' />
                                                        </InfoIconWrapper>
                                                        <Text darkMode={darkMode}>{expertData.expertInfo.email}</Text>
                                                    </ContactItem>

                                                    <ContactItem>
                                                        <InfoIconWrapper darkMode={darkMode}>
                                                            <img src={PhoneIcon} alt='phone icon' />
                                                        </InfoIconWrapper>
                                                        <Text darkMode={darkMode}>{expertData.expertInfo.phone}</Text>
                                                    </ContactItem>

                                                    <ContactItem>
                                                        <InfoIconWrapper darkMode={darkMode}>
                                                            <img src={PageIcon} alt='page icon' />
                                                        </InfoIconWrapper>
                                                        <Text darkMode={darkMode}>{expertData.expertInfo.about}</Text>
                                                    </ContactItem>


                                                </InfoContainer>
                                            </div>


                                        </SideContainer>

                                    </div>
                                </div>
                            ))

                        }
                    </FadeInWrapper>
                )
            }
        </>
    );
};

export default ChatPage;
